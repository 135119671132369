<template>
	<div class="view fixed-footer">
		<div class="main">
			<b-row>
				<b-col class="view-title">
					<p>수업 자료</p>
				</b-col>
			</b-row>
			<b-row>
				<b-col class="view-description">
					<p>수업 자료를 활용하시면 본 수업이 더욱 탄탄해집니다.</p>
				</b-col>
			</b-row>

			<b-container>
				<b-row class="container-title">
					<b-col class="text-left">
						<h2>메타버스 콘텐츠 이용 가이드</h2>
						<!-- <img class="container-title-img" src="@/assets/logo-09.png" alt="메타버스" /> -->
					</b-col>
				</b-row>
				<b-row>
					<b-col class="sub-title">
						<!-- <h3>수업활용 영상 보기</h3> -->
						<p>
							메타버스 콘텐츠 수업 시작 전 이용 가이드를 확인해주세요. <br />
							월페커즈, 영릉시간탐험대 각 콘텐츠별 공간의 구조와 수업의 흐름을 정리한 문서입니다.
						</p>
					</b-col>
				</b-row>
				<b-row align-h="center" class="no-padding">
					<b-col class="no-padding">
						<!-- <b-card-group deck>
							<b-card class="overflow-hidden tutorial" overlay footer-tag="footer" img-top img-src="/static/material-wallpeckers-new-1.png" img-alt="튜토리얼 영상 스크린샷 이미지">
								<b-card-text class="play"></b-card-text>
								<template #footer>
									<p>영릉 시간탐험대 튜토리얼</p>
									<b-link href="https://vimeo.com/711092367" target="_blank" class="stretched-link"></b-link> </template
							></b-card>
						</b-card-group> -->
						<b-row class="material-list">
							<b-col class="">
								<b-list-group-item class="inactive">
									<b-row>
										<b-col class="no-padding"
											>수업 자료 다운로드<b-link href="https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/metaverse_guide.zip" target="_blank" class="all-download"> <span>전체 받기</span></b-link></b-col
										>
									</b-row>
								</b-list-group-item>
								<b-list-group-item v-for="(item, i) in download_items_metaverse" v-bind:key="i" class="">
									<b-row align-h="center">
										<b-col class="download-item no-padding">
											<b-link :href="item.url" target="_blank"><b-icon :icon="item.icon"/> {{ item.desc }} <b-icon icon="download" class="ml-2"></b-icon></b-link>
										</b-col>
									</b-row>
								</b-list-group-item>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-container>
			<b-container v-if="$store.state.kits.includes('월페커즈')">
				<b-row class="container-title">
					<b-col class="text-left">
						<img class="container-title-img" src="@/assets/logo-02.png" alt="월페커즈인스쿨" />
					</b-col>
				</b-row>
				<b-row>
					<b-col class="sub-title">
						<h3>수업활용 영상 보기</h3>
						<p>수업 시작 전 영상을 확인하세요. 이미지를 클릭하면 영상을 볼 수 있습니다.</p>
					</b-col>
				</b-row>
				<b-row align-h="center" class="no-padding">
					<b-col class="no-padding">
						<b-card-group deck>
							<!-- <b-card
								class="overflow-hidden"
								overlay
								title="월페커즈 연수영상 보러가기"
								img-top
								img-src="/static/material-wallpeckers-1.png"
								img-alt="노트북 컴퓨터로 월페커즈인스쿨 앳홈을 플레이하고 있는 학생 뒷모습"
							>
								<b-card-text>
									<b-link href="http://bit.ly/35tmWAt" class="stretched-link">
										미래엔 엠티처의 ‘놀공스쿨’ 소개 페이지로 연결됩니다.<br class="wide-only" />
										엠티처에 로그인 후, ‘월페커즈 신청하기’ 버튼을 클릭해 연수영상을 시청하세요.
										<div class="wide-only">
											<br />
											1차시: 월페커즈 – DMZ에서 베를린 장벽까지<br />
											2차시: 교사용 웹서비스 및 사전/사후 수업가이드<br />
											3차시: 원격수업은 월페커즈 앳홈으로
										</div>
									</b-link>
								</b-card-text>
							</b-card> -->
							<b-card class="overflow-hidden tutorial" overlay footer-tag="footer" img-top img-src="/static/material-wallpeckers-new-1.png" img-alt="튜토리얼 영상 스크린샷 이미지">
								<!-- <b-card-text>월페커즈인스쿨 튜토리얼<b-link href="https://https://vimeo.com/669635807" target="_blank" class="stretched-link"></b-link></b-card-text> -->
								<b-card-text class="play"></b-card-text>
								<template #footer>
									<p>월페커즈 튜토리얼</p>
									<b-link href="https://vimeo.com/669635807" target="_blank" class="stretched-link"></b-link> </template
							></b-card>
							<b-card class="overflow-hidden tutorial" overlay footer-tag="footer" img-top img-src="/static/material-wallpeckers-new-2.png" img-alt="수업활용 1편: 월페커즈 게임 스크린샷  이미지">
								<b-card-text class="play"></b-card-text>
								<template #footer>
									<span>수업활용 1편</span>
									<p>월페커즈 게임 방법</p>
									<b-link href="https://vimeo.com/667028163" target="_blank" class="stretched-link"></b-link>
								</template>
							</b-card>
							<b-card class="overflow-hidden tutorial" overlay footer-tag="footer" img-top img-src="/static/material-wallpeckers-new-3.png" img-alt="수업활용 2편: 월페커즈 앳홈(원격수업용) 게임 스크린샷 이미지">
								<b-card-text class="play"></b-card-text>
								<template #footer>
									<span>수업활용 2편</span>
									<p>월페커즈 앳홈(원격수업용) <br />게임 방법</p>
									<b-link href="https://vimeo.com/667028809" target="_blank" class="stretched-link"></b-link>
								</template>
							</b-card>
							<b-card class="overflow-hidden tutorial" overlay footer-tag="footer" img-top img-src="/static/material-wallpeckers-new-4.png" img-alt="수업활용 3편: 월페커즈 게임 셋팅 및 수업자료 안내 스크린샷 이미지">
								<b-card-text class="play"></b-card-text>
								<template #footer>
									<span>수업활용 3편</span>
									<p>월페커즈 게임 셋팅 및 <br />수업자료 안내</p>
									<b-link href="https://vimeo.com/667028075" target="_blank" class="stretched-link"></b-link>
								</template>
							</b-card>
						</b-card-group>
						<b-row class="material-list">
							<b-col class="">
								<b-list-group-item class="inactive">
									<b-row>
										<b-col class="no-padding"
											>수업 자료 다운로드<b-link href="https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/wallpeckers.zip" target="_blank" class="all-download"> <span>전체 받기</span></b-link></b-col
										>
									</b-row>
								</b-list-group-item>
								<b-list-group-item v-for="(item, i) in download_items_wallpeckers" v-bind:key="i" class="">
									<b-row align-h="center">
										<b-col class="download-item no-padding">
											<b-link :href="item.url" target="_blank"><b-icon :icon="item.icon"/> {{ item.desc }} <b-icon icon="download" class="ml-2"></b-icon></b-link>
										</b-col>
									</b-row>
								</b-list-group-item>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-container>

			<b-container v-if="$store.state.kits.includes('건축놀이사무소')">
				<b-row class="container-title">
					<b-col class="text-left">
						<img class="container-title-img" src="@/assets/logo-01.png" alt="월페커즈인스쿨" />
					</b-col>
				</b-row>
				<b-row>
					<b-col class="sub-title">
						<h3>수업활용 영상 보기</h3>
						<p>수업 시작 전 영상을 확인하세요. 이미지를 클릭하면 영상을 볼 수 있습니다.</p>
					</b-col>
				</b-row>
				<b-row align-h="center" class="no-padding">
					<b-col class="no-padding">
						<b-card-group deck>
							<!-- <b-card
								class="overflow-hidden"
								overlay
								title="건축놀이사무소 연수영상 보러가기"
								img-top
								img-src="/static/material-gunnolsa-1.png"
								img-alt="노트북 컴퓨터로 월페커즈인스쿨 앳홈을 플레이하고 있는 학생 뒷모습"
							>
								<b-card-text>
									<b-link href="http://bit.ly/3nwQUcV" class="stretched-link">
										미래엔 엠티처의 ‘놀공스쿨’ 소개 페이지로 연결됩니다.<br class="wide-only" />
										엠티처에 로그인 후, ‘건축놀이사무소 신청하기’ 버튼을 클릭해 연수영상을 시청하세요.
										<div class="wide-only">
											<br />
											1차시: 공간감수성과 건축놀이사무소<br />
											2차시: 건축놀이사무소 수업활용팁<br />
											3차시: 건축놀이사무소를 응용한 수업활동
										</div>
									</b-link>
								</b-card-text>
							</b-card> -->
							<b-card class="overflow-hidden" overlay footer-tag="footer" img-top img-src="/static/material-gunnolsa-new-1.png" img-alt="노트북 컴퓨터로 월페커즈인스쿨 앳홈을 플레이하고 있는 학생 뒷모습">
								<b-card-text class="play"></b-card-text>
								<template #footer>
									<p>건축놀이사무소 소개</p>
									<b-link href="https://vimeo.com/667028916" target="_blank" class="stretched-link"> </b-link>
								</template>
							</b-card>
							<b-card class="overflow-hidden" overlay footer-tag="footer" img-top img-src="/static/material-gunnolsa-new-2.png" img-alt="노트북 컴퓨터로 월페커즈인스쿨 앳홈을 플레이하고 있는 학생 뒷모습">
								<b-card-text class="play"></b-card-text>
								<template #footer>
									<span>수업활용 1편</span>
									<p>공간감수성과 <br />건축놀이사무소</p>
									<b-link href=" https://vimeo.com/667028314" target="_blank" class="stretched-link"> </b-link>
								</template>
							</b-card>
							<b-card class="overflow-hidden" overlay footer-tag="footer" img-top img-src="/static/material-gunnolsa-new-3.png" img-alt="노트북 컴퓨터로 월페커즈인스쿨 앳홈을 플레이하고 있는 학생 뒷모습">
								<b-card-text class="play"></b-card-text>
								<template #footer>
									<span>수업활용 2편</span>
									<p>건축놀이사무소 수업활용 팁</p>
									<b-link href="https://vimeo.com/667028681" target="_blank" class="stretched-link"> </b-link>
								</template>
								<b-card-text> </b-card-text>
							</b-card>
							<b-card class="overflow-hidden" overlay footer-tag="footer" img-top img-src="/static/material-gunnolsa-new-4.png" img-alt="노트북 컴퓨터로 월페커즈인스쿨 앳홈을 플레이하고 있는 학생 뒷모습">
								<b-card-text class="play"></b-card-text>
								<template #footer>
									<span>수업활용 3편</span>
									<p>건축놀이사무소를 응용한 <br />수업활동</p>
									<b-link href="https://vimeo.com/667028514" target="_blank" class="stretched-link"> </b-link>
								</template>
							</b-card>
							<!-- <b-card class="overflow-hidden" overlay title="활동지 모아 뽑기" img-top img-src="/static/material-gunnolsa-2.png" img-alt="튜토리얼 영상 스크린샷 이미지">
								<b-card-text><b-link href="https://vimeo.com/667028916" target="_blank" class="stretched-link"></b-link></b-card-text>
							</b-card> -->
							<!-- <b-card class="overflow-hidden" overlay title="활동지 모아 뽑기" img-top img-src="/static/material-gunnolsa-2.png" img-alt="튜토리얼 영상 스크린샷 이미지">
								<b-card-text
									><b-link
										href="https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/%ED%95%99%EC%8A%B5%EC%9E%90%EB%A3%8C_%EB%AA%A8%EC%95%84%EB%BD%91%EA%B8%B0.zip"
										target="_blank"
										class="stretched-link"
									></b-link
								></b-card-text>
							</b-card> -->
						</b-card-group>
						<b-row class="material-list">
							<b-col class="">
								<b-list-group-item class="inactive">
									<b-row>
										<b-col class="no-padding"
											>수업 자료 다운로드<b-link href="https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/%ED%95%99%EC%8A%B5%EC%9E%90%EB%A3%8C_%EB%AA%A8%EC%95%84%EB%BD%91%EA%B8%B0.zip" target="_blank" class="all-download"> <span>전체 받기</span></b-link></b-col
										>
									</b-row>
								</b-list-group-item>
								<b-list-group-item v-for="(item, i) in download_items_gunnolsa" v-bind:key="i" class="">
									<b-row align-h="center">
										<b-col class="download-item no-padding">
											<b-link :href="item.url" target="_blank"><b-icon :icon="item.icon"/> {{ item.desc }} <b-icon icon="download" class="ml-2"></b-icon></b-link>
										</b-col>
									</b-row>
								</b-list-group-item>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-container>

			<FeedbackIcon />
		</div>
		<Footer />
	</div>
</template>

<script>
// Navigation bar 컴포넌트
import FeedbackIcon from '@/components/FeedbackIcon';
import Footer from '@/components/Footer';
// 교육자료실 페이지
export default {
	name: 'Material',
	data() {
		return {
			download_items_metaverse: [
				{
					desc: '영릉 시간탐험대 메타버스 이용 가이드 (pdf)',
					url:
						'https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%85%E1%85%B3%E1%86%BC_%E1%84%89%E1%85%B5%E1%84%80%E1%85%A1%E1%86%AB%E1%84%90%E1%85%A1%E1%86%B7%E1%84%92%E1%85%A5%E1%86%B7%E1%84%83%E1%85%A2_%E1%84%86%E1%85%A6%E1%84%90%E1%85%A1%E1%84%87%E1%85%A5%E1%84%89%E1%85%B3_%E1%84%8B%E1%85%B5%E1%84%8B%E1%85%AD%E1%86%BC_%E1%84%80%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B3.pdf',
					icon: 'file-text',
				},
				{
					desc: '월페커즈 메타버스 이용 가이드 (pdf)',
					url:
						'https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/%E1%84%8B%E1%85%AF%E1%86%AF%E1%84%91%E1%85%A6%E1%84%8F%E1%85%A5%E1%84%8C%E1%85%B3_%E1%84%86%E1%85%A6%E1%84%90%E1%85%A1%E1%84%87%E1%85%A5%E1%84%89%E1%85%B3_%E1%84%8B%E1%85%B5%E1%84%8B%E1%85%AD%E1%86%BC_%E1%84%80%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B3.pdf',
					icon: 'file-text',
				},
				// {
				// 	desc: "영릉 시간탐험대 튜토리얼 영상 (mp4)",
				// 	url:
				// 		"https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/%E1%84%8B%E1%85%AF%E1%86%AF%E1%84%91%E1%85%A6%E1%84%8F%E1%85%A5%E1%84%8C%E1%85%B3_%E1%84%90%E1%85%B2%E1%84%90%E1%85%A9%E1%84%85%E1%85%B5%E1%84%8B%E1%85%A5%E1%86%AF%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%89%E1%85%A1%E1%86%BC.mp4",
				// 	icon: "film",
				// },
				// {
				// 	desc: "전체 받기",
				// 	url: "https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/wallpeckers.zip",
				// 	icon: "",
				// },
			],
			download_items_wallpeckers: [
				{
					desc: '수업교안 (hwp)',
					url: 'https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/%ED%86%B5%EC%9D%BC_%EC%9B%94%ED%8E%98%EC%BB%A4%EC%A6%88_%EC%88%98%EC%97%85%EA%B5%90%EC%95%88.zip',
					icon: 'file-text',
				},
				{
					desc: '수업장표 (ppt)',
					url: 'https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/%ED%86%B5%EC%9D%BC_%EC%9B%94%ED%8E%98%EC%BB%A4%EC%A6%88_%EC%88%98%EC%97%85%EC%9E%A5%ED%91%9C.zip',
					icon: 'collection-play',
				},
				{
					desc: '소개 영상 (mp4)',
					url: 'https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/%EC%9B%94%ED%8E%98%EC%BB%A4%EC%A6%88_%EC%86%8C%EA%B0%9C%EC%98%81%EC%83%81.mp4',
					icon: 'film',
				},
				{
					desc: '튜토리얼 영상 (mp4)',
					url:
						'https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/%E1%84%8B%E1%85%AF%E1%86%AF%E1%84%91%E1%85%A6%E1%84%8F%E1%85%A5%E1%84%8C%E1%85%B3_%E1%84%90%E1%85%B2%E1%84%90%E1%85%A9%E1%84%85%E1%85%B5%E1%84%8B%E1%85%A5%E1%86%AF%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%89%E1%85%A1%E1%86%BC.mp4',
					icon: 'film',
				},
				// {
				// 	desc: "전체 받기",
				// 	url: "https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/wallpeckers.zip",
				// 	icon: "",
				// },
			],
			download_items_gunnolsa: [
				{
					desc: '건축놀이사무소_수업안 (hwp)',
					url: 'https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/%EA%B1%B4%EC%B6%95%EB%86%80%EC%9D%B4%EC%82%AC%EB%AC%B4%EC%86%8C_%EC%88%98%EC%97%85%EC%95%88.hwp',
					icon: 'file-text',
				},
				{
					desc: '학습자료_1차시_공간이름짓기',
					url: 'https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/%ED%95%99%EC%8A%B5%EC%9E%90%EB%A3%8C_1%EC%B0%A8%EC%8B%9C_%EA%B3%B5%EA%B0%84%EC%9D%B4%EB%A6%84%EC%A7%93%EA%B8%B0.zip',
					icon: 'collection-play',
				},
				{
					desc: '학습자료_2차시_몸자측정일지',
					url: 'https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/%ED%95%99%EC%8A%B5%EC%9E%90%EB%A3%8C_2%EC%B0%A8%EC%8B%9C_%EB%AA%B8%EC%9E%90%EC%B8%A1%EC%A0%95%EC%9D%BC%EC%A7%80.zip',
					icon: 'collection-play',
				},
				{
					desc: '학습자료_본수업_건축놀이사무소',
					url: 'https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/%ED%95%99%EC%8A%B5%EC%9E%90%EB%A3%8C_%EB%B3%B8%EC%88%98%EC%97%85_%EA%B1%B4%EC%B6%95%EB%86%80%EC%9D%B4%EC%82%AC%EB%AC%B4%EC%86%8C.zip',
					icon: 'collection-play',
				},
				{
					desc: '학습자료_5차시_건축놀이감리하기',
					url: 'https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/%ED%95%99%EC%8A%B5%EC%9E%90%EB%A3%8C_5%EC%B0%A8%EC%8B%9C_%EA%B1%B4%EC%B6%95%EB%86%80%EC%9D%B4%EA%B0%90%EB%A6%AC%ED%95%98%EA%B8%B0.zip',
					icon: 'collection-play',
				},
				{
					desc: '(건축놀이사무소)빈공간카드양식 (pdf)',
					url: 'https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/(%EA%B1%B4%EC%B6%95%EB%86%80%EC%9D%B4%EC%82%AC%EB%AC%B4%EC%86%8C)%EB%B9%88%EA%B3%B5%EA%B0%84%EC%B9%B4%EB%93%9C%EC%96%91%EC%8B%9D.pdf',
					icon: 'file-text',
				},
			],
		};
	},
	metaInfo: {
		// 페이지 제목 설정
		title: '수업 자료',
		titleTemplate: '%s | NOLGONG SCHOOL',
		meta: [
			// SEO 설정
			{ name: 'description', content: '선생님의 도움으로 제작된 수업 교안, 튜토리얼 영상 등', vmid: 'description' },
			{ name: 'author', content: '(주)놀공' },
			{ name: 'subject', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>' },
			// SNS 설정
			{
				property: 'og:type',
				content: 'website',
				vmid: 'og:type',
			},
			{
				property: 'og:title',
				content: '수업 자료',
				vmid: 'og:title',
			},
			{
				property: 'og:description',
				content: '선생님의 도움으로 제작된 수업 교안, 튜토리얼 영상 등',
				vmid: 'og:description',
			},
			{
				property: 'og:url',
				content: 'https://nolgongschool.com/material',
				vmid: 'og:url',
			},
			{
				property: 'og:image',
				content: 'https://nolgongschool.com/static/social_image_horizontal.png',
				vmid: 'og:image',
			},
			{
				property: 'og:image:alt',
				content: '"NOLGONG SCHOOL" 문구, 건물과 깃발을 표현한 초록색 학교 모양 아이콘',
				vmid: 'og:image:alt',
			},
			{
				property: 'og:image:width',
				content: '800',
				vmid: 'og:image:width',
			},
			{
				property: 'og:image:height',
				content: '400',
				vmid: 'og:image:height',
			},
			{
				property: 'og:locale',
				content: 'ko_KR',
				vmid: 'og:locale',
			},
			{
				property: 'og:site_name',
				content: 'NOLGONG SCHOOL',
				vmid: 'og:site_name',
			},
			{
				property: 'twitter:card',
				content: 'summary',
				vmid: 'twitter:card',
			},
			{
				property: 'twitter:site',
				content: '@nolgong',
				vmid: 'twitter:site',
			},
			{
				property: 'twitter:title',
				content: '수업 자료',
				template: (chunk) => `${chunk} | NOLGONG SCHOOL`,
				vmid: 'twitter:title',
			},
			{
				property: 'twitter:description',
				content: '선생님의 도움으로 제작된 수업 교안, 튜토리얼 영상 등',
				vmid: 'twitter:description',
			},
			{
				property: 'twitter:image',
				content: 'https://nolgongschool.com/static/social_image_horizontal.png',
				vmid: 'twitter:image',
			},
			{
				property: 'twitter:image:alt',
				content: '"NOLGONG SCHOOL" 문구, 건물과 깃발을 표현한 초록색 학교 모양 아이콘',
				vmid: 'twitter:image:alt',
			},
		],
	},
	components: {
		FeedbackIcon,
		Footer,
	},
};
</script>

<style scoped>
span {
	font-size: 16px;
	color: #808080;
	font-weight: 400;
}

.sub-title {
	padding-top: 30px;
}

.sub-title h3 {
	color: #000000;
	font-size: 20px;
}

.sub-title p {
	color: #808080;
	font-size: 16px;
}

.card-deck {
	display: block;
	margin: 1rem 0;
}

.card-deck .card {
	border: none;
	border-radius: 0;
}

.card-deck .card .position-relative {
	border: 1px solid #c4c4c4;
}

.no-padding {
	padding: 0;
}
.container .row {
	padding: 0;
}
a {
	color: #666;
}
.card-title {
	font-weight: 800;
	border-radius: 4px;
	transition: all 0.25s;
	text-shadow: 1px 1px 2px #fff, 1px -1px 2px #fff, -1px 1px 2px #fff, -1px -1px 2px #fff;
}
.card-text {
	opacity: 0;
	transition: opacity 0.25s;
}
.card-img-top {
	border-radius: 0;
	transition: all 0.25s;
}
.card:hover .card-title {
	background: rgba(0, 0, 0, 0);
	text-shadow: none;
	color: white;
}
.card:hover .card-text {
	opacity: 1;
}
.card:hover .card-img-top {
	filter: brightness(0.5);
	/* filter: blur(3px) brightness(0.5); */
}
.card-text a {
	color: white;
}
.play {
	position: absolute;
	top: 50%;
	left: 50%;
	right: 0;
	bottom: 0;
	transform: translate(-50%, -50%);
	width: 0;
	height: 0;
	border-top: 13px solid transparent;
	border-left: 26px solid #fff;
	border-bottom: 13px solid transparent;
}
.play:after {
	content: '';
	position: absolute;
	top: 50%;
	left: -17px;
	right: 0;
	bottom: 0;
	/* border: 10px solid white;
	border-radius: 50%; */
	font-size: 8em;
	display: inline-block;
	width: 62px;
	height: 62px;
	border: 1px solid white;
	transform: translate(-50%, -50%);
	/* position: relative; */
	border-radius: 50%;
	box-sizing: content-box;
}

.card-footer {
	padding: 0.75rem 0;
	background: none;
	border: none;
}

.card-footer > p {
	margin-bottom: 0;
	font-size: 20px;
}

.card-footer > span {
	font-size: 16px;
	color: #808080;
}

.material-list > div {
	padding: 16px 0;
	border-top: 1px solid #e3e3e3;
}

.list-group-item {
	padding: 0.75rem 0;
	border-left: 0;
	border-right: 0;
	/* border-bottom: 1px solid #e3e3e3; */
}
.list-group-item:first-of-type {
	border-radius: 0;
	border: 0;
}

.list-group-item:first-of-type div {
	font-size: 20px;
}
/* .list-group-item:last-of-type {
	border-radius: 0 0 0.25rem 0.25rem;
} */
.list-group-item:not(.inactive):hover {
	background: rgba(0, 0, 0, 0.1);
}
.container-title {
	margin: 0;
	padding: 0.25em 1em !important;
	border-bottom: 1px solid #ebebeb;
}
.container-title h2 {
	/* font-size: 40px; */
	font-weight: 600;
}
.container-title-img {
	max-width: 200px;
	margin: 0 0 1em;
}

.all-download {
	position: absolute;
	right: -20px;
	color: #ff6936;
}

.all-download > span {
	color: inherit;
}

.download-item > a {
	position: relative;
	display: block;
}

.bi-download {
	position: absolute;
	right: 0;
}

@supports (display: none) {
	.card-deck {
		display: flex;
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 1140px;
	}
}

@media (max-width: 1200px) {
	.wide-only {
		display: none;
	}
}

@media (max-width: 992px) {
	.wide-only {
		display: block;
	}
	.card-deck .card {
		margin: 0 0 1em;
	}
	.card-deck {
		display: block;
		margin-left: 0px;
		margin-right: 0px;
	}
	.col {
		padding: 0;
	}
	.material-list {
		margin: 0;
	}
}

@media (max-width: 576px) {
	.wide-only {
		display: none;
	}
	/* .container .row.no-padding {
		margin: 0;
	} */
	.card-title {
		font-size: 1.25rem;
	}
}
</style>
